// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-seller-js": () => import("/codebuild/output/src215070393/src/src/templates/seller.js" /* webpackChunkName: "component---src-templates-seller-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src215070393/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src215070393/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-outback-js": () => import("/codebuild/output/src215070393/src/src/pages/outback.js" /* webpackChunkName: "component---src-pages-outback-js" */),
  "component---src-pages-privacy-js": () => import("/codebuild/output/src215070393/src/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-salve-js": () => import("/codebuild/output/src215070393/src/src/pages/salve.js" /* webpackChunkName: "component---src-pages-salve-js" */),
  "component---src-pages-terms-js": () => import("/codebuild/output/src215070393/src/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

