import React from 'react';
import fetch from 'isomorphic-fetch';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import AppProvider from './src/store/provider';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const httpLink = createHttpLink({
  uri: process.env.API_ENDPOINT
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

//TODO: why we are using isomorphic-fetch?
const client = new ApolloClient({
  fetch,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return (
    <AppProvider>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </AppProvider>
  );
};
